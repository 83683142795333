import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import { Section, Container } from "../global"

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "logo" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return(
    <Section id="features">
    <StyledContainer>
      <SectionImage>
        <StyledImage fluid={data.file.childImageSharp.fluid} />
      </SectionImage>
      {/* <Subtitle>ChinaToolset</Subtitle> */}
      <SectionTitle>We make traveling in China
        <br /> 
        smooth as a butter
      </SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>One-click services</FeatureTitle>
          <FeatureText>
            Find day-to-day services you would need as you travel and explore the country.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Map that just works</FeatureTitle>
          <FeatureText>
            Local maps in English, so you don't have to deal
            with the frustrations of Google Map not "working" behind the wall.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Pay with ease</FeatureTitle>
          <FeatureText>
            Pay with your local currency,
            available for travellers ONLY.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Local guides</FeatureTitle>
          <FeatureText>
            Meet local guides who understand and speak English.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>COVID-19 friendly</FeatureTitle>
          <FeatureText>Up to date info from local authorities, delivered in English</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>100% coverage</FeatureTitle>
          <FeatureText>
            Covers all first-tier cities in China
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
  )
}

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
const SectionImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 250px;
`

