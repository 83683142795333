import React from "react"
import styled from "styled-components"
import { Section, Container } from "../global"

const Pricing = () => (
  <Section id="pricing">
	<SectionTitle>Pricing Plans</SectionTitle>
	<Flex>
		<Card>
			<PriceTextGroup>
				<h2>Tourists</h2>
				<h3>$15/user</h3> for 14 days
				<p>
					For single-use travellers who are here for a short stay. 
					Get access to all key features such as:
				</p>
				<ul>
					<li>Local maps in English</li>
					<li>Generate QR code with Visa/Mastercard for payment</li>
					<li>Local guides to chat with</li>
					<li>Access to services such as SIM card, public transport cards and more.</li>
				</ul>
			</PriceTextGroup>
		</Card>
		<Card>
			<PriceTextGroup>
				<h2>Residence</h2>
				<h3>$18/user</h3> per month
				<p>
					For frequent travellers or foreigners residing in China for work.
					Get access to core features in tourists plan, plus:
				</p>
				<ul>
					<li>Step by step guide to activate WeChat and Alipay</li>
					<li>Services to register for bank accounts, credit cards and more.</li>
				</ul>
			</PriceTextGroup>
		</Card>
		<Card>
			<PriceTextGroup>
				<h2>Corporate</h2>
				<h3>$35/user</h3> per month
				<p>
					For businesses who want to ensure a smooth relocation of employees to China.
					Get access to core features in tourists and residence plan, plus:
				</p>
				<ul>
					<li>Reminders and preparations prior departure</li>
					<li>Auto-generation of to do lists and due dates for smooth onboarding experience</li>
					<li>Community of foreigners within and outside the company</li>
				</ul>
			</PriceTextGroup>
		</Card>
	</Flex>
  </Section>
)

export default Pricing

const Flex = styled.div`
  display: grid;
  justify-content: space-evenly;
  align-content: center;
  grid-template-columns: repeat(3, 30%);
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const PriceTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h2 {
    margin-bottom: -34px;
		color: ${props => props.theme.color.primary};
  }

  p {
    margin-bottom: 48px;
  }
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Card = styled.div`
  padding: 3rem;
	border-radius: 0.5rem;
	border-style: solid;
	--tw-border-opacity: 1;
	border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  boxShadow: '0 10px 28px rgba(0,0,0,.08)';
`